var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"aggregation-table",class:{ 'fixed-first-column': _vm.isUserAggregation },attrs:{"loading":_vm.loading,"headers":_vm.aggregationTable.headers,"items":_vm.aggregationTable.items,"header-props":{ sortByText: 'ソート' },"footer-props":{ itemsPerPageText: '行/ページ:' },"no-data-text":"データがありません","loading-text":"読込中...","hide-default-footer":_vm.aggregationTable.items.length === 1},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"transparent"}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.aggregationTable.name))]),_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary","ripple":false,"disabled":_vm.loading || _vm.aggregationTable.items.length === 0 || _vm.isAllSaved,"small":""},on:{"click":function($event){$event.preventDefault();return _vm.$emit('save-all', _vm.aggregationTable.id)}}},[_vm._v(" 保存 ")]),_c('v-btn',{attrs:{"icon":"","depressed":"","ripple":false,"loading":_vm.loading,"disabled":_vm.loading},on:{"click":function($event){$event.preventDefault();return _vm.$emit('reload', _vm.aggregationTable.id)}}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiReload)+" ")])],1),_c('v-divider',{staticClass:"mx-2",attrs:{"vertical":"","inset":""}}),_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menuOn = ref.on;
var menuAttrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',menuAttrs,false),menuOn),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}},{key:"default",fn:function(menu){return [_c('v-list',{attrs:{"disabled":_vm.loading,"dense":""}},[_c('v-subheader',[_vm._v("操作")]),(_vm.isUserAggregation)?_c('AggregationUsersDialogListItem',{attrs:{"disabled":_vm.loading,"aggregation-users":_vm.aggregationUsers,"available-users":_vm.availableUsers},on:{"create":function($event){return _vm.$emit('create-user-aggregation', $event)},"fetch-available-users":function($event){return _vm.$emit('fetch-available-users', $event)},"destroy":function($event){return _vm.$emit('destroy-user-aggregation', $event)},"cancel":function($event){menu.value = false}}}):_vm._e(),_c('v-dialog',{attrs:{"max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var listOn = ref.on;
var listAttrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"disabled":_vm.loading}},'v-list-item',listAttrs,false),listOn),[_c('v-list-item-title',{staticClass:"error--text"},[_vm._v(" リセットして再取得 ")])],1)]}},{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-toolbar',{attrs:{"flat":"","color":"transparent"}},[_vm._v(" 確認 ")]),_c('v-card-text',[_vm._v(" 保存された結果をすべてクリアし、再計算と再取得を行います。よろしいですか？ ")]),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"text":""},on:{"click":function () {
                        dialog.value = false;
                        menu.value = false;
                      }}},[_vm._v(" キャンセル ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function () {
                        _vm.$emit('refresh', _vm.aggregationTable.id)
                        dialog.value = false;
                        menu.value = false;
                      }}},[_vm._v(" OK ")])],1)],1)]}}],null,true)})],1)]}}])})],1)]},proxy:true},_vm._l((_vm.aggregationTable.businessRuleHeaders),function(header){return {key:("item." + (header.value)),fn:function(ref){
                      var item = ref.item;
return [_c('v-menu',{key:("business-rule-result-input-for-current-club-business-rule-id-" + (header.value)),attrs:{"close-on-content-click":false,"nudge-width":200,"disabled":_vm.isNotEditable(item, header.value)},on:{"input":function($event){return _vm.onMenuInput($event, item, header.value)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var editMenuOn = ref.on;
                      var editMenuAttrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',editMenuAttrs,false),editMenuOn),[(_vm.isBooleanItem(item, header.value))?_c('v-checkbox',{class:{ 'new-item': _vm.isNew(item, header.value) },attrs:{"input-value":item[header.value],"disabled":_vm.loading,"readonly":"","dense":"","hide-details":""}}):_c('v-text-field',{class:{ 'new-item': _vm.isNew(item, header.value) },attrs:{"value":item[header.value],"disabled":_vm.loading,"type":_vm.inputType(item, header.value),"readonly":"","dense":"","hide-details":""}})],1)]}},{key:"default",fn:function(editMenu){return [_c('v-card',[_c('v-card-subtitle',[_vm._v(_vm._s(header.text))]),_c('v-card-text',[(_vm.isBooleanItem(item, header.value))?_c('v-checkbox',{attrs:{"autofocus":"","dense":"","hide-details":"","disabled":_vm.isNotEditable(item, header.value)},model:{value:(item[header.value]),callback:function ($$v) {_vm.$set(item, header.value, $$v)},expression:"item[header.value]"}}):_c('v-text-field',{attrs:{"autofocus":"","type":_vm.inputType(item, header.value),"dense":"","hide-details":"","disabled":_vm.isNotEditable(item, header.value)},model:{value:(item[header.value]),callback:function ($$v) {_vm.$set(item, header.value, $$v)},expression:"item[header.value]"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","small":""},on:{"click":function($event){editMenu.value = false;
                item[header.value] = item.meta.originalValues[header.value];}}},[_vm._v(" キャンセル ")]),_c('v-btn',{attrs:{"color":"primary","small":"","disabled":_vm.isNotEditable(item, header.value)},on:{"click":function($event){editMenu.value = false;
                _vm.saveResultValue(item, header.value, item[header.value]);}}},[_vm._v(" 保存 ")])],1)],1)]}}],null,true)})]}}})],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }