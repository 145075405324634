/* eslint-disable no-param-reassign */
import Vue from 'vue'
import { ref, computed } from '@vue/composition-api'

// aggregationsRef ... AggregationFetchService から取得した Aggregation のリスト
export default (aggregationsRef = ref([])) => {
  const sortAndFilterAggregationType = (aggregations, type) => {
    return [...aggregations]
      .filter(aggregation => aggregation.attributes.type === type)
      .sort((a, b) => a.attributes.position - b.attributes.position)
  }

  const buildAggregationMap = aggregations => {
    return aggregations.reduce((grouped, aggregation) => {
      const { id } = aggregation.attributes
      grouped[id] = aggregation

      return grouped
    }, {})
  }

  /**
   * Aggregation の結果を特定するためのユニークなキーを生成します。
   *
   * @param {string} contextType - コンテキストの種類 ("User" または "Club")。
   * @param {number|null} [contextId=null] - コンテキストの ID。Club の場合は null。
   * @param {number} businessRuleId - ビジネスルールの ID。
   * @returns {string} - 生成されたマッピングキー。
   */
  const generateMappingKey = (contextType, contextId = null, businessRuleId) => {
    return `${contextType}-${contextId || 'null'}-${businessRuleId}`
  }

  /**
   * Aggregation の結果を効率的に参照するためのマップを生成します。
   * contextType、contextId、businessRuleId の組み合わせをキーとして、
   * 対応する result.attributes を値とするマップを作成します。
   *
   * @type {import('@vue/composition-api').ComputedRef<Object.<string, Object>>}
   */
  const aggregationResultsMap = computed(() => {
    const resultMap = {}
    aggregationsRef.value.forEach(aggregation => {
      aggregation.attributes.contexts.data.forEach(context => {
        const { id: contextId, contextType } = context.attributes
        context.attributes.results.data.forEach(result => {
          const { businessRuleId } = result.attributes
          const key = generateMappingKey(contextType, contextId, businessRuleId)
          resultMap[key] = result.attributes
        })
      })
    })

    return resultMap
  })

  /**
   * BusinessRuleResult のデータを使用して、対応する Aggregation の結果を更新します。
   * aggregationResultsMap を利用して効率的に対象を特定し、値を更新します。
   *
   * interface BusinessRuleResult {
   *   id: string;
   *   type: string;
   *   attributes: {
   *     id: number;
   *     businessRuleId: number;
   *     resultValue: number | string | boolean | null;
   *     contextId: number;
   *     contextType: "User" | "Club";
   *     businessDate: string;
   *     createdAt: string;
   *     isDirty: boolean;,
   *   }
   * }
   * @param {BusinessRuleResult} businessRuleResult - 更新に使用する BusinessRuleResult オブジェクト。
   */
  const updateAggregationResult = businessRuleResult => {
    const {
      contextType,
      contextId,
      resultValue,
      businessRuleId,
      id: businessRuleResultId,
    } = businessRuleResult.attributes

    const key = generateMappingKey(contextType, contextId, businessRuleId)
    const targetResultAttributes = aggregationResultsMap.value[key]
    if (targetResultAttributes) {
      Vue.set(targetResultAttributes, 'value', resultValue)
      Vue.set(targetResultAttributes, 'businessRuleResultId', businessRuleResultId)
    }
  }

  /**
   * 依存している集計結果のコンテキストを探します
   * @param {Object} params
   * @param {Array} params.aggregations
   * @param {number} [params.businessRuleId]
   * @param {Object.<string, number[]>} [params.resources]
   * @param {boolean} [params.onlySavedResults] - 依存関係を探す際に保存済みの結果のみを対象とするか
   * @returns {Array<{ contextType: string; contextId: number | null; businessRuleId: number }>} - contextがClubの場合はcontextIdはnull
   */
  const findDependents = ({
    aggregations,
    businessRuleId,
    resources = {},
    context = { contextId: null, contextType: null },
    onlySavedResults = false,
    visited = new Set(), // 再帰中に訪問済みの依存関係を記録
    excludeDependent = null, // 除外対象のコンテキスト
  }) => {
    const dependents = []
    const myBusinessRuleIds = Array.isArray(businessRuleId) ? [...businessRuleId] : [businessRuleId]
    const myResources = resources
    const { contextId: myContextId = null, contextType: myContextType } = context

    const skipBusinessRuleIdMatching = businessRuleId === null
    const skipResourceMatching = Object.values(myResources).every(resourceIds => resourceIds.length === 0)
    const skipMyContextMatching = myContextType === null && myContextId === null

    const addDependent = ({ result, contextId, contextType }) => {
      if (onlySavedResults && result.attributes.businessRuleResultId === null) return

      const {
        businessRuleId: otherBusinessRuleId,
        dependentResources: otherDependentResources,
        dependentBusinessRuleIds: otherDependentBusinessRuleIds,
      } = result.attributes

      const isDependentOnMyBusinessRule = skipBusinessRuleIdMatching
        || myBusinessRuleIds.some(id => otherDependentBusinessRuleIds?.includes(id))

      const isDependentOnMyResources = skipResourceMatching
        || Object.entries(myResources).some(([myResourceType, myResourceIds]) => {
          const otherResourceIds = otherDependentResources?.[myResourceType] || []

          return myResourceIds.some(resourceId => otherResourceIds.includes(resourceId))
        })

      if (isDependentOnMyBusinessRule && isDependentOnMyResources) {
        const key = `${contextType}:${contextId}:${otherBusinessRuleId}`
        if (
          excludeDependent
          && contextType === excludeDependent.contextType
          && contextId === excludeDependent.contextId
          && otherBusinessRuleId === excludeDependent.businessRuleId
        ) {
          return
        }

        if (!visited.has(key)) {
          visited.add(key) // 訪問済みとして記録
          dependents.push({ contextType, contextId, businessRuleId: otherBusinessRuleId })

          // 再帰的にさらに依存関係を探索
          const childDependents = findDependents({
            aggregations,
            businessRuleId: otherBusinessRuleId,
            resources: otherDependentResources || {},
            context: { contextId, contextType },
            onlySavedResults,
            visited,
            excludeDependent,
          })

          dependents.push(...childDependents)
        }
      }
    }

    aggregations.forEach(aggregation => {
      aggregation.attributes.contexts.data.forEach(cxt => {
        const { id: contextId, contextType, results } = cxt.attributes
        if (skipMyContextMatching) {
          results.data.forEach(result => addDependent({ result, contextId, contextType }))
        } else if (
          contextType === myContextType
            && (myContextId === null || contextId === myContextId)
        ) {
          results.data.forEach(result => addDependent({ result, contextId, contextType }))
        }
      })
    })

    return dependents
  }

  return {
    aggregationResultsMap,
    findDependents,
    updateAggregationResult,
    sortAndFilterAggregationType,
    buildAggregationMap,
  }
}
