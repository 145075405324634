<template>
  <v-dialog
    max-width="400"
    @click:outside="resetParams"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        color="primary"
        :ripple="false"
        :disabled="disabled"
        small
        v-bind="attrs"
        v-on="on"
      >
        新規作成
      </v-btn>
    </template>

    <template #default="dialog">
      <v-card>
        <v-card-title>新規作成</v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-combobox
                :value="selectedUserName"
                label="ユーザー"
                :items="users"
                item-text="attributes.name"
                dense
                autofocus
                @change="onUserSelect"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="params.startAt"
                label="開始"
                type="datetime-local"
                dense
                @focus="setDefaultDateTime('startAt')"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="params.leaveAt"
                label="終了"
                type="datetime-local"
                :min="params.startAt"
                :disabled="!params.startAt"
                dense
                @focus="setDefaultDateTime('leaveAt')"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="params.lateMinutes"
                label="遅刻/分"
                type="number"
                dense
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="params.earlyLeaveMinutes"
                label="早退/分"
                type="number"
                dense
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <v-select
                v-model="params.timecardStatusId"
                label="ステータス"
                :items="timecardStatuses"
                item-text="attributes.name"
                item-value="attributes.id"
                dense
                clearable
              />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            small
            @click="close(dialog)"
          >
            キャンセル
          </v-btn>
          <v-btn
            color="primary"
            :disabled="isInvalid"
            small
            @click="create(dialog)"
          >
            新規作成
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import {
  toRefs,
  reactive,
  computed,
} from '@vue/composition-api'
import { format } from 'date-fns'

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    timecardStatuses: {
      type: Array,
      default: () => [],
    },
    users: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const { users } = toRefs(props)

    const params = reactive({
      userId: null,
      startAt: null,
      leaveAt: null,
      lateMinutes: 0,
      earlyLeaveMinutes: 0,
      timecardStatusId: null,
    })

    const selectedUserName = computed(() => {
      const { userId } = params
      if (userId) {
        return users.value.find(user => user.attributes.id === userId)?.attributes?.name
      }

      return ''
    })

    const isInvalid = computed(() => {
      const { userId, startAt } = params

      if (!userId || !startAt) return true

      if (params.leaveAt && startAt) {
        const startDate = new Date(startAt)
        const leaveDate = new Date(params.leaveAt)

        // leaveAt が startAt より前の場合、無効
        if (leaveDate < startDate) return true
      }

      return false
    })

    const setDefaultDateTime = key => {
      if (!params[key]) {
        params[key] = format(new Date(), "yyyy-MM-dd'T'HH:mm")
      }
    }

    const resetParams = () => {
      params.userId = null
      params.startAt = null
      params.leaveAt = null
      params.lateMinutes = 0
      params.earlyLeaveMinutes = 0
      params.timecardStatusId = null
    }

    const close = dialog => {
      // eslint-disable-next-line no-param-reassign
      dialog.value = false
      resetParams()
    }

    const create = dialog => {
      if (isInvalid.value) return

      const paramsToEmit = { ...params }
      emit('create', paramsToEmit)
      close(dialog)
    }

    const onUserSelect = item => {
      if (item?.attributes?.id) {
        params.userId = item.attributes.id
      }
    }

    return {
      params,
      selectedUserName,
      isInvalid,
      close,
      create,
      resetParams,
      onUserSelect,
      setDefaultDateTime,
    }
  },
}
</script>
