<template>
  <div>
    <v-row v-if="showSkeletonLoader">
      <v-col cols="12">
        <v-skeleton-loader type="table" />
      </v-col>
    </v-row>

    <template v-else>
      <v-row
        v-for="agg in userAggregationsData"
        :key="`aggregation-${agg.id}`"
      >
        <v-col cols="12">
          <v-skeleton-loader
            v-if="jobStatuses[agg.id] === 'in_progress'"
            type="table"
          />
          <AggregationTable
            v-else-if="userAggregationsMap[agg.id]"
            :aggregation="userAggregationsMap[agg.id]"
            :loading="loadingStatuses[agg.id]"
            :aggregation-users="aggregationUsers(agg.id)"
            :available-users="availableUsers[agg.id]"
            @save-all="enqueueGenerateBusinessRuleResults"
            @save="generateBusinessRuleResult"
            @reload="reloadAggregation"
            @refresh="refreshAggregation"
            @create-user-aggregation="createUserAggregation(agg.id, $event)"
            @destroy-user-aggregation="destroyUserAggregation(agg.id, $event)"
            @fetch-available-users="handleFetchAvailableUsers(agg.id, $event)"
          ></AggregationTable>
          <AggregationEmptyTable
            v-else
            :id="agg.id"
            :name="agg.attributes.name"
            @reload="reloadAggregation"
          />
        </v-col>
      </v-row>

      <v-row
        v-for="agg in clubAggregationsData"
        :key="`aggregation-${agg.id}`"
      >
        <v-col cols="12">
          <v-skeleton-loader
            v-if="jobStatuses[agg.id] === 'in_progress'"
            type="table-heading, list-item-two-line"
          />
          <AggregationTable
            v-else-if="clubAggregationsMap[agg.id]"
            :aggregation="clubAggregationsMap[agg.id]"
            :loading="loadingStatuses[agg.id]"
            @save-all="enqueueGenerateBusinessRuleResults"
            @save="generateBusinessRuleResult"
            @reload="reloadAggregation"
            @refresh="refreshAggregation"
          ></AggregationTable>
          <AggregationEmptyTable
            v-else
            :id="agg.id"
            :name="agg.attributes.name"
            @reload="reloadAggregation"
          />
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import Vue from 'vue'
import {
  ref,
  reactive,
  computed,
  toRefs,
  onMounted,
  watch,
} from '@vue/composition-api'
import AggregationApi from '@/api/v2/eod-closing/Aggregation'
import AggregationUserApi from '@/api/v2/eod-closing/AggregationUser'
import BusinessRuleApi from '@/api/v2/eod-closing/BusinessRule'
import useAggregation from '../composables/useAggregation'
import useCurrentData from '@/views/composable/useCurrentData'
import AggregationTable from './AggregationTable.vue'
import AggregationEmptyTable from './AggregationEmptyTable.vue'

export default {
  components: {
    AggregationTable,
    AggregationEmptyTable,
  },
  props: {
    date: {
      type: String,
      required: true,
    },
    enqueue: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { date, enqueue } = toRefs(props)

    const isLoading = ref(true)
    const aggregations = ref([])
    const aggregationsData = ref([])
    const loadingStatuses = reactive({})
    const jobStatuses = ref({})
    const availableUsers = ref({})

    const {
      aggregationResultsMap,
      findDependents,
      updateAggregationResult,
      sortAndFilterAggregationType,
      buildAggregationMap,
    } = useAggregation(aggregations)

    const { currentClub } = useCurrentData()

    const showSkeletonLoader = computed(() => isLoading.value || !date.value)

    const isAnyJobInProgress = computed(() => Object.values(jobStatuses.value).some(status => status === 'in_progress'))
    const isAllJobCompleted = computed(() => Object.values(jobStatuses.value).every(status => status === 'completed'))
    const isAnyJobMissing = computed(() => Object.values(jobStatuses.value).some(status => status === 'failed' || status === null))
    const isAllJobMissing = computed(() => Object.values(jobStatuses.value).every(status => status === 'failed' || status === null))

    const userAggregationsData = computed(() => sortAndFilterAggregationType(aggregationsData.value, 'UserAggregation'))
    const clubAggregationsData = computed(() => sortAndFilterAggregationType(aggregationsData.value, 'ClubAggregation'))
    const userAggregationsMap = computed(() => buildAggregationMap(sortAndFilterAggregationType(aggregations.value, 'UserAggregation')))
    const clubAggregationsMap = computed(() => buildAggregationMap(sortAndFilterAggregationType(aggregations.value, 'ClubAggregation')))

    const aggregationUsers = computed(() => id => {
      const agg = aggregationsData.value.find(ag => ag.attributes.id === Number(id))
      if (agg?.attributes?.type === 'UserAggregation') {
        return agg.attributes.aggregationUsers.data
      }

      return []
    })

    const upsertAggregation = data => {
      const idx = aggregations.value.findIndex(ag => ag.attributes.id === data.attributes.id)

      if (idx >= 0) {
        aggregations.value.splice(idx, 1, data)
      } else {
        aggregations.value.push(data)
      }
    }

    const getAggregationJobStatus = async id => {
      try {
        const res = await AggregationApi.getAggregationJobStatus(id, date.value)
        if (res.data.status === 'success') {
          const { status, result } = res.data.data
          jobStatuses.value = { ...jobStatuses.value, [id]: status }
          if (result) upsertAggregation(result.data)
        }
      } catch (error) {
        if (error.response.status === 404) {
          jobStatuses.value = { ...jobStatuses.value, [id]: null }
        }
      }
    }

    const pollAggregationJobStatus = async id => {
      let intervalTime = 5000 // 初期間隔 5 秒
      const interval = setInterval(async () => {
        try {
          await getAggregationJobStatus(id)
          if (['completed', 'failed', null].includes(jobStatuses.value[id])) {
            clearInterval(interval)
          }
        } catch (error) {
          clearInterval(interval)
        }
        intervalTime = Math.min(intervalTime * 2, 60000) // 最大1分まで増加
      }, intervalTime)
    }

    const enqueueAggregation = async (id, options = {}) => {
      const { forceReload = false, forceRefresh = false } = options
      try {
        const res = await AggregationApi.enqueueAggregation(id, date.value, { forceReload, forceRefresh })
        if (res.status === 202) {
          jobStatuses.value = { ...jobStatuses.value, [id]: 'in_progress' }
          pollAggregationJobStatus(id)
        }
      } catch (error) {
        if (error.response.status === 404) {
          jobStatuses.value = { ...jobStatuses.value, [id]: null }
        }
        // TODO
      }
    }

    // 初期化時
    const getAggregations = async () => {
      isLoading.value = true
      try {
        const res = await AggregationApi.getAggregations(date.value)
        if (res.data.status === 'success') {
          const { data } = res.data.data
          aggregationsData.value = data
          const aggregationIds = []
          data.forEach(async ag => {
            Vue.set(loadingStatuses, ag.attributes.id, false)
            jobStatuses.value = { ...jobStatuses.value, [ag.attributes.id]: 'in_progress' }
            availableUsers.value = { ...availableUsers.value, [ag.attributes.id]: [] }
            aggregationIds.push(ag.attributes.id)
          })
          // jobステータス確認
          await Promise.all(aggregationIds.map(id => getAggregationJobStatus(id)))
        }
      } catch (error) {
        // TODO エラーハンドリング
      } finally {
        isLoading.value = false
      }
    }

    // 保存結果以外を再計算
    const reloadAggregation = id => {
      enqueueAggregation(id, { forceReload: true })
    }

    // 保存結果を削除してすべてを再計算
    const refreshAggregation = id => {
      enqueueAggregation(id, { forceRefresh: true })
    }

    // 一括保存（手動で値を変更しての保存はないので依存関係は気にしなくていい）
    const enqueueGenerateBusinessRuleResults = async (id, options = {}) => {
      const { forceReload = false, forceRefresh = false } = options
      try {
        const res = await BusinessRuleApi.enqueueGenerateBusinessRuleResults(id, date.value, { forceReload, forceRefresh })
        if (res.status === 202) {
          jobStatuses.value = { ...jobStatuses.value, [id]: 'in_progress' }
          pollAggregationJobStatus(id)
        }
      } catch (error) {
        if (error.response.status === 404) {
          jobStatuses.value = { ...jobStatuses.value, [id]: null }
        }
      }
    }

    const getUserDependents = (businessRuleId, contextId) => {
      return [
        ...findDependents({
          aggregations: aggregations.value,
          businessRuleId,
          context: { contextId, contextType: 'User' },
          excludeDependent: { contextId, contextType: 'User', businessRuleId },
        }),
        ...findDependents({
          aggregations: aggregations.value,
          businessRuleId,
          context: { contextId: currentClub.value.id, contextType: 'Club' },
        }),
      ]
    }
    const getClubDependents = businessRuleId => {
      return [
        ...findDependents({
          aggregations: aggregations.value,
          businessRuleId,
          context: { contextId: null, contextType: 'User' },
        }),
        ...findDependents({
          aggregations: aggregations.value,
          businessRuleId,
          context: { contextId: currentClub.value.id, contextType: 'Club' },
          excludeDependent: { contextId: currentClub.value.id, contextType: 'Club', businessRuleId },
        }),
      ]
    }

    const generateBusinessRuleResult = async ({
      aggregationId,
      contextType,
      contextId,
      businessRuleId,
      resultValue,
    }) => {
      if (contextType === 'User' && !contextId) throw new TypeError('who tf is this?')
      const dependents = {
        User: getUserDependents(businessRuleId, contextId),
        Club: getClubDependents(businessRuleId),
      }[contextType] || []

      Object.keys(loadingStatuses).forEach(id => { loadingStatuses[id] = true })
      try {
        const res = await BusinessRuleApi.generateBusinessRuleResult({
          aggregationId,
          contextType,
          contextId,
          businessRuleId,
          resultValue,
          date: date.value,
          dependents,
        })
        if (res.data.status === 'success') {
          res.data.data.data.forEach(businessRuleResult => updateAggregationResult(businessRuleResult))
        }
      } catch (error) {
        // TODO エラーハンドリング
      } finally {
        Object.keys(loadingStatuses).forEach(id => { loadingStatuses[id] = false })
      }
    }

    const createUserAggregation = async (
      id,
      { userId, callback } = { userId: null, callback: () => {} },
    ) => {
      try {
        const res = await AggregationUserApi.createAggregationUser(id, userId)
        if (res.data.status === 'success') {
          const { data } = res.data.data
          const aggIdx = aggregationsData.value.findIndex(ag => ag.attributes.id === Number(id))
          if (aggIdx < 0) return

          const updatedAggData = { ...aggregationsData.value[aggIdx] }
          updatedAggData.attributes.aggregationUsers.data.push(data)
          aggregationsData.value.splice(aggIdx, 1, updatedAggData)

          const updatedAvailableUsers = [...availableUsers.value[id]]
          const userIdx = updatedAvailableUsers.findIndex(user => user.attributes.id === Number(userId))
          if (userIdx < 0) return

          updatedAvailableUsers.splice(userIdx, 1)
          availableUsers.value = { ...availableUsers.value, [id]: updatedAvailableUsers }
        }
      } catch (error) {
        // TODO エラーハンドリング
      } finally {
        callback()
      }
    }

    const destroyUserAggregation = async (
      aggregationId,
      { id, userId, callback } = { id: null, userId: null, callback: () => {} },
    ) => {
      try {
        const res = await AggregationUserApi.destroyAggregationUser(id)
        if (res.data.status === 'success') {
          const { data } = res.data.data

          const aggIdx = aggregationsData.value.findIndex(ag => ag.attributes.id === Number(aggregationId))
          if (aggIdx < 0) return

          const updatedAggData = { ...aggregationsData.value[aggIdx] }
          const aggUserIdx = updatedAggData.attributes.aggregationUsers.data.findIndex(aggUser => aggUser.attributes.id === Number(id))
          if (aggUserIdx < 0) return

          updatedAggData.attributes.aggregationUsers.data.splice(aggUserIdx, 1)
          aggregationsData.value.splice(aggIdx, 1, updatedAggData)

          const updatedAvailableUsers = [...availableUsers.value[id]]
          const userIdx = updatedAvailableUsers.findIndex(user => user.attributes.id === Number(userId))
          if (userIdx < 0) return

          updatedAvailableUsers.splice(userIdx, 1, data)
          availableUsers.value = { ...availableUsers.value, [id]: updatedAvailableUsers }
        }
      } catch (error) {
        // TODO エラーハンドリング
      } finally {
        callback()
      }
    }

    const handleFetchAvailableUsers = async (id, callback = () => {}) => {
      try {
        const res = await AggregationApi.getAvailableUsers(id)
        if (res.data.status === 'success') {
          const { data } = res.data.data
          availableUsers.value = { ...availableUsers.value, [id]: data }
        }
      } catch (error) {
        // TODO エラーハンドリング
      } finally {
        callback()
      }
    }

    const emitJobStatuses = () => {
      emit('is-any-job-in-progress', isAnyJobInProgress.value)
      emit('is-all-job-completed', isAllJobCompleted.value)
      emit('is-any-job-missing', isAnyJobMissing.value)
    }
    watch(
      () => enqueue.value,
      async newVal => {
        if (newVal && isAnyJobMissing.value) {
          emit('enqueued')

          Object.entries(jobStatuses.value).forEach(([id, status]) => {
            if (status === null || status === 'failed') {
              enqueueAggregation(id)
            }
          })
        }
      },
    )
    watch(() => jobStatuses.value, () => {
      emitJobStatuses()
    }, { immediate: true })

    onMounted(async () => {
      await getAggregations()
    })

    return {
      aggregations,
      userAggregationsMap,
      clubAggregationsMap,
      userAggregationsData,
      clubAggregationsData,
      loadingStatuses,
      jobStatuses,
      availableUsers,
      isAnyJobInProgress,
      isAnyJobMissing,
      isAllJobCompleted,
      isAllJobMissing,
      aggregationUsers,

      showSkeletonLoader,
      aggregationResultsMap,

      reloadAggregation,
      refreshAggregation,
      enqueueGenerateBusinessRuleResults,
      generateBusinessRuleResult,
      createUserAggregation,
      destroyUserAggregation,
      handleFetchAvailableUsers,
    }
  },
}
</script>
