<template>
  <v-data-table
    :headers="[]"
    :items="[]"
    :header-props="{ sortByText: 'ソート' }"
    :footer-props="{ itemsPerPageText: '行/ページ:' }"
    no-data-text="データがありません"
    loading-text="読込中..."
    hide-default-footer
    class="aggregation-table"
  >
    <template #top>
      <v-toolbar
        flat
        color="transparent"
      >
        <v-toolbar-title>{{ name }}</v-toolbar-title>
        <v-spacer />
        <v-btn
          icon
          depressed
          :ripple="false"
          @click.prevent="$emit('reload', id)"
        >
          <v-icon>
            {{ icons.mdiReload }}
          </v-icon>
        </v-btn>
      </v-toolbar>
    </template>
  </v-data-table>
</template>

<script>
import { mdiReload } from '@mdi/js'

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {
      icons: {
        mdiReload,
      },
    }
  },
}
</script>
